import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
// ...GatsbyImageSharpFluid
const query = graphql`
  query {
    file(relativePath: { eq: "hero-img.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Hero = () => {
  const data = useStaticQuery(query);
  return(
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info" >
          <div><h1 >Hi</h1>
            <div className="underline" />
              <h2>i'm shulav Karki </h2>
              <h4>Computer Engineering Student</h4>
              <Link to='/contant' className="btn">
                contact me
              </Link>
              <SocialLinks />
            </div>
        </article>
        <Image fluid={data.file.childImageSharp.fluid} className="hero-img" />
      </div>
    </header>
  )
}

export default Hero
