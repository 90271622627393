import React from "react"
import Title from "./Title"
import { FaAngleDoubleRight, FaSearchLocation } from "react-icons/fa"
import { ImLocation } from "react-icons/im"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
// sort: {fields: id, order: DESC}
const query = graphql`
  {
    allStrapiEducations(sort: {fields: id, order: DESC}) {
      nodes {
        strapiId
        title
        name
        date
        location
        desc {
          id
          name
        }
      }
    }
  }
`
const Jobs = () => {
  const data = useStaticQuery(query)
  const{allStrapiEducations:{nodes:edus}} = data
  const [value, setValue] = React.useState(0)
  const {name,title, date, desc,location} = edus[value]
  return (
    <section className="section jobs bg-grey">
      <Title title="education" />
      <div className="jobs-center">
        <div className="btn-container">
          {
            edus.map((item, index) => {
              return(
                <button 
                  key={item.strapiId} 
                  onClick={()=> setValue(index)}
                  className={`job-btn ${index==value && "active-btn"}`}
                > 
                  {item.title}
                </button>
              ) 
            })
          }
        </div>
        <article className="job-info">
          <h3>{title}</h3>
          <h4>{name}</h4>
          <p className="location-txt"><ImLocation className="job-icon"></ImLocation>{location}</p>
          <p className="job-date">{date}</p>
          {desc.map(item => {
            return (
              <div key={item.id} className="job-desc">
                <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                <p>{item.name}</p>
              </div>
            )
          })}
        </article>
      </div>
      <Link to="/about" className="btn center-btn">
        more info
      </Link>
    </section>
  )
}
export default Jobs
