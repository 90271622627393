import React from "react"
import Title from "./Title"
import services from "../constants/services"
const Services = () => {
  return (
    <section className="section">
      <Title title="my skills" />
      <div className="section-center services-center">
      <li>
        <h3>
          HTML5
        </h3>
        <span className="bar"><span className="html"></span></span>
      </li>

      <li>
        <h3>
          CSS
        </h3>
        <span className="bar"><span className="css"></span></span>
      </li>

      <li>
        <h3>
          React
        </h3>
        <span className="bar"><span className="React"></span></span>
      </li>

      <li>
        <h3>
          Python
        </h3>
        <span className="bar"><span className="Python"></span></span>
      </li>

      

      <li>
        <h3>
          Django
        </h3>
        <span className="bar"><span className="Django"></span></span>
      </li>
      
      {/* <li>
        <h3>
          Deep Learning
        </h3>
        <span className="bar"><span className="Deep"></span></span>
      </li> */}


      <li>
        <h3>
          C++
        </h3>
        <span className="bar"><span className="Cplus"></span></span>
      </li>


    </div>
  </section>

  )
}

export default Services
