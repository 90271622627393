import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"
export default ({data}) => {
  const {
    allStrapiProjects:{nodes:projects},
    allStrapiBlogs:{nodes:blogs},
  } = data;
 
  return(
    <Layout>
      <SEO title="Shulav" description="Home Page" />
      <Hero />
      <Jobs />
      <Services />
      <Projects projects={projects} title="featured projects" showLink/>
      <Blogs blogs={blogs} title="Blogs" showLink/>
    </Layout>
  )
  
}

export const query = graphql`
  {
    allStrapiProjects(filter: {Featured: {eq: true}}) {
      nodes {
        id
        name
        description
        github
        url
        Languages {
          id
          name
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiBlogs(sort: {fields: date, order: ASC}, limit: 3) {
      nodes {
        id
        title
        slug
        description
        date(formatString: "MMM Do YYYY")
        category
        image {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
